import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const ProjectWithUs = () => (
  <ProjectWrapper>
    <div className="project_cta">
      <h2>Need help moving the needle?</h2>
      <div className="subtitle">
        Let us be the fulcrum for your next project.
      </div>
    </div>
    <div className="project_contact">
      <Link to="/contact">Contact</Link>
    </div>
    <div className="block_quote">
      <div className="quote">
        “Give me a lever long enough and
        <br />
        a fulcrum on which to place it,
        <br />
        and I shall move the world.”
      </div>
      <div className="attribution">- Archimedes</div>
    </div>
  </ProjectWrapper>
);

const ProjectWrapper = styled.section`
  background: var(--primaryColor);
  color: var(--mainWhite);
  display: grid;
  font-size: 18px;
  text-align: center;
  align-items: center;
  padding: 5rem 1rem 0 1rem;
  h2 {
    font-size: 46px;
    margin-bottom: 1rem;
  }
  .project_cta {
    grid-column-start: 12;
    grid-column-end: 13;
  }
  .subtitle {
    font-size: 30px;
  }
  .project_contact {
    grid-column-start: 14;
    grid-column-end: 15;
    a {
      text-decoration: none;
      color: var(--mainWhite);
      border: 2px solid var(--mainWhite);
      padding: 1rem 2rem;
      text-align: center;
      border-radius: 2rem;
      width: 150px;
      transition: var(--mainTransition);

      &:hover {
        background: var(--mainWhite);
        color: var(--primaryColor);
      }
    }
  }

  .block_quote {
    grid-column-start: 1;
    grid-column-end: 25;
    padding-top: 6rem;
    font-size: 18px;
  }
  .quote {
    font-family: "Merriweather";
    font-size: 18px;
    font-style: italic;
    font-weight: 300;
  }
  .attribution {
    padding-right: 2rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }

  @media (max-width: 991px) {
    grid-template-columns: 1fr;

    .project_cta {
      grid-column-start: 1;
      grid-column-end: 1;
    }
    .subtitle {
      font-size: 22px;
    }
    .project_contact {
      grid-column-start: 1;
      grid-column-end: 1;
      padding-top: 3rem;
    }
    .block_quote {
      grid-column-start: 1;
      grid-column-end: 1;
    }
  }
  @media (max-width: 660px) {
    h2 {
      font-size: 30px;
      margin-bottom: 0.5rem;
    }
    .subtitle {
      font-size: 18px;
    }
    .project_contact {
      padding-top: 3rem;
    }
  }
`;

export default ProjectWithUs;
