/* eslint-disable no-use-before-define */
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import PortfolioTitle from "./PortfolioTitle";
import PortfolioList from "./PortfolioList";

const PortfolioItems = () => {
  const { items } = useStaticQuery(getPortfolioItems);
  const itemNodes = items.edges.map((x) => x.node);

  return (
    <section>
      <PortfolioTitle title="Case Studies" subtitle="" />
      <PortfolioList items={itemNodes} />
    </section>
  );
};

const getPortfolioItems = graphql`
  {
    items: allContentfulPortfolioItem(
      sort: { fields: publicationDate, order: DESC }
    ) {
      edges {
        node {
          id: contentful_id
          slug
          title
          company
          publicationDate
          description {
            text: description
          }
          images {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          category {
            slug
          }
        }
      }
    }
  }
`;

export default PortfolioItems;
