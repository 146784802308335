/* eslint-disable react/require-default-props */
import React from "react";
import Image from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import moment from "moment";
import styled from "styled-components";
import PropTypes from "prop-types";
// import styled from "../../css/blog-card.module.css";

const Card = ({ node }) => {
  const {
    slug,
    company,
    title,
    image,
    publicationDate,
    category,
    description,
  } = node;
  // console.log("Card -> node", node);
  return (
    <CardWrapper fade to={`/projects/${slug}`} data-cy="portfolio-item">
      <CardImage>
        <Image fluid={image.fluid} />
      </CardImage>
      <Body>
        <Company>{company}</Company>
        <Title>{title}</Title>
        <p>{description && description.text}</p>
        <Footer>
          <IconButton>
            Read Case Study
            <i className="fgicon-arrow" />
          </IconButton>
        </Footer>
      </Body>
    </CardWrapper>
  );
};

Card.propTypes = {
  node: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    category: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
    image: PropTypes.object.isRequired,
    description: PropTypes.object.isRequired,
  }),
};

const CardWrapper = styled(AniLink)`
  grid-column: container;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  text-align: left;
  position: relative;
  color: #1e2c33;
  padding-bottom: 0;
  margin-bottom: 2rem;
  min-width: 0;
  min-height: 300px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 8px 18px rgba(30, 44, 51, 0.12);
  transition: all 0.2s linear;
  &:hover {
    color: #1e2c33;
    box-shadow: 0 6px 28px rgba(30, 44, 51, 0.12);
    transform: translateY(-3px);
  }
  @media screen and (max-width: 757px) {
    flex-direction: column;
  }
`;

const CardImage = styled.div`
  flex: 1 0 0px;
  align-self: center;
  width: 100%;
  transition: var(--mainTransition);
  overflow: hidden;
  @media screen and (max-width: 757px) {
    flex: 1;
    border-bottom: 1px solid rgba(222, 226, 230, 0.5);
  }
`;

const Body = styled.div`
  flex: 1 0 0px;
  border-left: 1px solid rgba(222, 226, 230, 0.5);
  padding: 30px 30px 0;
  p {
    margin: 0;
    line-height: 1.6em;
  }
  @media screen and (max-width: 757px) {
    min-height: 360px;
  }
`;
const Company = styled.h3`
  font-size: 2rem;
  font-weight: 700;
  transition: all 0.2s;
  margin-bottom: 0.5rem;
  @media screen and (max-width: 757px) {
    font-size: 1.5rem;
  }
`;

const Title = styled.h3`
  font-size: 1.25rem;
  font-weight: 500;
  transition: all 0.2s;
  margin-bottom: 2rem;
  @media screen and (max-width: 757px) {
    font-size: 1rem;
    font-weight: 700;
  }
`;

const Footer = styled.div`
  display: flex;
  position: absolute;
  margin: 30px 30px 20px 30px;
  bottom: 0;
  right: 0;
  left: 0;
  p {
    opacity: 0.5;
    font-size: 0.9em;
    margin-top: -2px;
    margin-bottom: -2px;
  }
  i {
    font-size: 0.7em;
    position: relative;
    top: 1px;
    margin-left: 8px;
    margin-top: -2px;
    margin-bottom: -2px;
    max-height: 33px;
    transition: 0.2s ease-out;
    display: inline-block;
  }
`;
const IconButton = styled.div`
  color: rgba(30, 44, 51, 0.85);
  padding-right: 0;
  margin-left: auto !important;
  transition: all 0.2s linear;
  &:hover {
    i {
      color: var(--primaryColor);
      transform: translateX(5px);
    }
  }
`;

export default Card;
