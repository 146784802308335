import React from "react";
import styled from "styled-components";

const VideoBanner = ({ title, info, children, className }) => (
  <div className={className}>
    <h1>{title}</h1>
    <p>{info}</p>
    {children}
  </div>
);

export default styled(VideoBanner)`
  letter-spacing: var(--mainSpacing);
  color: white;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  h1 {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-shadow: 12px 4px 71px rgba(117, 117, 117, 0);
    margin: 1.25rem 1.25rem 0.25rem 1.25rem;
    line-height: 1.5rem;
  }
  p {
    text-align: left;
    margin: 0 1.25rem;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.5px;
  }
  @media screen and (min-width: 768px) {
    h1 {
      margin: 4rem 1rem 1rem 4rem;
      font-size: 3.5rem;
    }
    p {
      margin: 0 4rem;
      font-size: 2rem;
    }
  }
`;
