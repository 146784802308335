import React from "react";
import Layout from "../components/Layout";
import ProjectWithUs from "../components/Contact/ProjectWithUs";
import StyledVideoHero from "../components/StyledVideoHero";
import BannerVideo from "../components/BannerVideo";

import PortfolioItems from "../components/Portfolio/PortfolioItems";
import SEO from "../components/SEO";

// eslint-disable-next-line react/display-name
export default () => (
  <Layout>
    <SEO title="Projects" />
    <StyledVideoHero
      src="https://fulcrum.nyc3.digitaloceanspaces.com/rowing.mp4"
      mobileSrc="https://fulcrum.nyc3.digitaloceanspaces.com/rowing-mobile.mp4"
      backgroundScreen="0.2"
    >
      <BannerVideo title="Projects" />
    </StyledVideoHero>
    {/* <PortfolioCategoryItems /> */}
    <PortfolioItems />
    <ProjectWithUs />
  </Layout>
);
