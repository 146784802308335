/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Card from "./PortfolioCard";

const PortfolioList = ({ items }) => {
  return (
    <PortfolioItemsWrapper data-cy="portfolio-items">
      {items &&
        items.map((node) => {
          node.image = node.images ? node.images[0] : null;
          return <Card key={node.id} node={node} />;
        })}
    </PortfolioItemsWrapper>
  );
};

PortfolioList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const PortfolioItemsWrapper = styled.div`
  display: grid;
  padding: 4rem 1rem 8rem 1rem;
  grid-template-columns:
    [viewport-start] minmax(1em, 1fr)
    [container-start] minmax(0, 60em) [container-end]
    minmax(1em, 1fr) [viewport-end];
  @media screen and (max-width: 757px) {
    padding: 3rem 0 5rem 0;
  }
`;

export default PortfolioList;
