/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

// eslint-disable-next-line react/display-name
const StyledVideoHero = ({
  src,
  mobileSrc,
  children,
  backgroundScreen,
  className,
}) => {
  const isMobile = useMediaQuery({ query: "( max-device-width: 720px )" });
  const correctSrc = isMobile ? mobileSrc : src;
  const bg = backgroundScreen || 0;
  return (
    <div
      className={className}
      style={{ backgroundColor: `rgba(41, 60, 99, ${bg} )` }}
    >
      <video className="videoPlayer" autoPlay loop playsInline muted>
        <source src={correctSrc} type="video/mp4" />
        Your device does not support playing `&apos;`video/mp4`&apos;` videos
      </video>
      {children}
    </div>
  );
};

export default styled(StyledVideoHero)`
  min-height: 200px;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 1 !important;
  display: flex;
  justify-content: start;
  align-items: center;
  .videoPlayer {
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  @media (min-width: 720px) {
    min-height: 450px;
  }
`;
